import type { HTMLAttributes, ReactElement, Ref } from 'react';
import type { DiveProps } from '../../types/Dive.types';
import type { LinkProps } from '../Link/Link.types';
import type { ValueOf } from 'ts-essentials';

export const TooltipTransition = {
  Fade: 'fade',
  MoveFade: 'move-fade',
  NoTransition: 'no-transition',
} as const;
export type TooltipTransition = ValueOf<typeof TooltipTransition>;

export const TooltipPosition = {
  TopLeft: 'top-start',
  TopCenter: 'top',
  TopRight: 'top-end',
  BottomLeft: 'bottom-start',
  BottomCenter: 'bottom',
  BottomRight: 'bottom-end',
  Right: 'right',
  Left: 'left',
} as const;
export type TooltipPosition = ValueOf<typeof TooltipPosition>;

export type TooltipProps = DiveProps & {
  /**
   * Text or React element to be displayed
   */
  content: string | ReactElement;
  /**
   * The position of the Tooltip relative to its `reference` element
   *
   * @defaultValue 'top'
   */
  position?: TooltipPosition;
  /**
   * The default width of the Tooltip. Possible values are:
   * - `fixed`: takes a fixed min width of `7.25rem` and a max width of`21rem`.
   * - `fluid`: takes the full width of its content with a max width of`21rem`.
   *
   * @defaultValue 'fluid'
   */
  width?: TooltipViewWidth;
  /**
   * The Tooltip's children element.
   */
  children: ReactElement<HTMLAttributes<HTMLElement>>;
  /**
   * Whether the tooltip should be displayed when its `children` is hovered.
   * @defaultValue true
   */
  showOnHover?: boolean;
  /**
   * Whether the tooltip should be visible by default. When set to true, prop `showOnHover` is ignored.
   * @defaultValue false
   */
  isVisible?: boolean;
  /**
   * Indicates the transition type.
   * @defaultValue 'move-fade'
   */
  transition?: TooltipTransition;
  /**
   * Link component for tooltip. Tooltip with link should be used with controlled isVisible
   */
  link?: ReactElement<LinkProps>;
  /**
   * React ref.
   */
  propRef?: Ref<HTMLElement>;
};

export const TooltipViewWidth = {
  Fixed: 'fixed',
  Fluid: 'fluid',
} as const;
export type TooltipViewWidth = ValueOf<typeof TooltipViewWidth>;
