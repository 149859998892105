import type { ValueOf } from 'ts-essentials';
import type { DiveProps } from '../../types/Dive.types';

/** The different variants of the ratings. */
export const RatingsVariant = {
  Primary: 'primary',
  Secondary: 'secondary',
  Tertiary: 'tertiary',
} as const;
export type RatingsVariant = ValueOf<typeof RatingsVariant>;

export type RatingsProps = {
  /**
   * The variant of the ratings. It's can be primary, secondary, or tertiary.
   */
  variant: RatingsVariant;
  /**
   * Rating value
   */
  rating: number;
  /**
   * The height of ratings icons.
   */
  height?: string | number;
} & DiveProps;

export const MAX_RATING_ICONS_BY_VARIANT = {
  [RatingsVariant.Primary]: 5,
  [RatingsVariant.Secondary]: 4,
  [RatingsVariant.Tertiary]: 4,
};
