import type { HashObjectReturn } from '@dce-front/onewebapp-util-react';
import type { JSX } from 'react';
import styles from './Metadatas.css';
import type { Metadata } from './Metadatas.type';
import MetadatasItem from './MetadatasItem';

type MetadatasProps = { metadatas: HashObjectReturn<Metadata>[] };

/**
 * Metadatas Component
 *
 * This component is in charge of displaying
 * the metadatas of a media
 *
 * @param metadatas The list of metadatas items
 */
function Metadatas({ metadatas }: MetadatasProps): JSX.Element {
  return (
    <ul className={styles.metadatas}>
      {metadatas.map((metadata) => (
        <li key={metadata.hash}>
          <MetadatasItem metadata={metadata} />
        </li>
      ))}
    </ul>
  );
}

export default Metadatas;
