import type { ApiV2DetailV5 } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import { getQsValueByKey } from '@dce-front/onewebapp-utils';
import { SafetyCodeContext } from '@dce-front/types-acm';
import type { JSX } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import LoadableAdultDisclaimerConnected from '../../../components/AdultDisclaimer/LoadableAdultDisclaimerConnected';
import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import { MetaTagsTemplate } from '../../../components/MetaTags/MetaTagsTemplate';
import Spinner from '../../../components/Spinner/Spinner';
import { QueryKeys } from '../../../constants/queryKeys';
import { useAppLocation } from '../../../helpers/hooks/reactRouter';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { FocusManager } from '../../../helpers/oneNavigation/FocusManager';
import type { FetchDetails } from '../../../services/types';
import { FetchRequestTypes } from '../../../services/types';
import { adultBrowsingSelector } from '../../../store/slices/adult-selectors';
import {
  featDetailTabHighlightsSelector,
  featDetailTabRankingSelector,
  getFeatureToggleDetailTabRating,
  getFeatureToggleDetailTabStatistics,
  getFeatureToggleDetailTabTimeline,
  getFeatureToggleShortVideoList,
} from '../../../store/slices/application-selectors';
import { immersiveAdultSelector } from '../../../store/slices/immersive-selectors';
import DetailV5 from '../../../templates/DetailV5/components/DetailV5';
import { DetailProvider } from '../../../templates/DetailV5/data/provider';
import { QS_PROCESS_STATE } from '../../../templates/FunnelTvod/helpers/const';
import { isDetailPageRootUrl } from '../data/helper';
import type { DetailContextState } from '../data/types';

export type DetailV5ContainerProps = {
  url: string;
  onClickParameters?: FetchDetails['onClickParameters'];
  focusManager?: FocusManager;
  isMainTemplate?: boolean;
};

function DetailV5Container({
  focusManager,
  onClickParameters,
  url,
  isMainTemplate,
}: DetailV5ContainerProps): JSX.Element | null {
  const { pathname } = useAppLocation();

  const callbackState = getQsValueByKey(url, QS_PROCESS_STATE);
  const isFeatDetailTabHighlights = useInvariantSelector(
    featDetailTabHighlightsSelector,
  );
  const isFeatDetailTabRanking = useInvariantSelector(
    featDetailTabRankingSelector,
  );
  const isFeatDetailTabTimeline = useInvariantSelector(
    getFeatureToggleDetailTabTimeline,
  );
  const isFeatDetailTabStatistics = useInvariantSelector(
    getFeatureToggleDetailTabStatistics,
  );
  const isFeatDetailTabRating = useInvariantSelector(
    getFeatureToggleDetailTabRating,
  );
  const isFeatShortVideoList = useInvariantSelector(
    getFeatureToggleShortVideoList,
  );
  const isAdultBrowsing = useSelector(adultBrowsingSelector);
  const isAdult = useSelector(immersiveAdultSelector);
  const [{ isLoading, error, data }] = useQueryTemplate<ApiV2DetailV5>(
    url,
    {
      onClickParameters,
      template: FetchRequestTypes.DetailV5,
      options: { queryKeyPrefix: QueryKeys.DetailPage },
      isMainTemplate,
    },
    undefined,
    {
      isFeatDetailTabHighlights,
      isFeatDetailTabTimeline,
      isFeatShortVideoList,
      isFeatDetailTabRanking,
      isFeatDetailTabStatistics,
      isFeatDetailTabRating,
    },
  );

  const showAdultDisclaimer = isAdult && !isAdultBrowsing;

  const initData = useMemo(() => {
    return {
      ...data,
      callbackState,
      isFunnelTvodOpened: false,
    } as DetailContextState;
  }, [data, callbackState]);

  const isDetailPageRoot = useMemo(() => {
    return isDetailPageRootUrl(pathname);
  }, [pathname]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorTemplate error={error} />;
  }

  if (showAdultDisclaimer) {
    return (
      <LoadableAdultDisclaimerConnected
        safetyCodeContext={SafetyCodeContext.Parental}
      />
    );
  }

  return data ? (
    <DetailProvider initData={initData}>
      <MetaTagsTemplate
        data={data}
        enableAlternateLinksUpdate
        enableMetaSnippets={isDetailPageRoot}
      />
      <DetailV5 focusManager={focusManager} />
    </DetailProvider>
  ) : null;
}

export default DetailV5Container;
