import type { ValueOf } from 'ts-essentials';
import type { DiveProps } from '../../../types/Dive.types';
import { RatingsVariant } from '../Ratings.types';
/**
 * The fill states for the stars in ratings.
 *
 * Used for the rating variants of "primary" and "secondary"
 *
 * Half fill is only for Primary variant.
 */
export const RatingsStarFill = {
  Full: 'full',
  Half: 'half',
  Empty: 'empty',
} as const;
export type RatingsStarFill = ValueOf<typeof RatingsStarFill>;

export type RatingsStarProps = {
  /** The variant of star, can be Primary or Secondary */
  variant: Exclude<RatingsVariant, 'tertiary'>;
  /** Fill state of the start, can be full, half or empty */
  fill: RatingsStarFill;
} & DiveProps;
