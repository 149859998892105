import {
  Button,
  ButtonWidth,
  type ButtonVariant,
  type DiveFontFamily,
} from '@dce-front/dive';
import type {
  PrimaryActionOnClick,
  PrimaryActionOnClickOption,
} from '@dce-front/hodor-types/modules/action_layout/primary/definitions';
import {
  Binder,
  KEY_BACK,
  useActiveLayer,
  useKeyCatcher,
} from '@dce-front/one-navigation';
import { Template } from '@dce-front/sdk-hodor';
import { ModalV2, type ModalV2Props } from '@dce-front/sharedcomponent';
import classNames from 'classnames/bind';
import { useCallback, type JSX } from 'react';
import { getLocationStateContext } from '../../helpers/contents/contents-helper';
import { LAYER_DIALOG_MODAL } from '../../helpers/oneNavigation/layers';
import Linker from '../Linker/Linker';
import styles from './DialogModal.css';

const cx = classNames.bind(styles);

type Option = {
  disableOnClick?: boolean;
  font?: `${DiveFontFamily}`;
  width?: `${ButtonWidth}`;
  handleClick?: () => void;
  id: string;
  label: string;
  variant?: ButtonVariant;
};

export type DialogModalProps = {
  description?: string | null;
  options: (PrimaryActionOnClickOption | Option)[];
  playBtnHandler?: (onClick?: PrimaryActionOnClick) => void;
  title?: string;
} & Pick<
  ModalV2Props,
  | 'className'
  | 'classNameContainer'
  | 'classNameHeader'
  | 'classNameBody'
  | 'classNameBackdrop'
  | 'onClose'
>;

function DialogModal({
  description,
  options,
  playBtnHandler,
  title,
  onClose,
  className,
  classNameContainer,
  classNameHeader,
  classNameBody,
  classNameBackdrop,
}: DialogModalProps): JSX.Element {
  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleOnClick = useCallback(
    (onClick?: PrimaryActionOnClick) => {
      handleClose();
      // Launch Player for selected DialogModal option:
      if (onClick?.displayTemplate === Template.Player) {
        playBtnHandler?.(onClick);
      }
    },
    [handleClose, playBtnHandler],
  );

  useActiveLayer(LAYER_DIALOG_MODAL, $_BUILD_RENDERMODE_CSR);
  useKeyCatcher(KEY_BACK, handleClose, LAYER_DIALOG_MODAL);

  return (
    <ModalV2
      id="dialogModal"
      title={title}
      className={className}
      classNameContainer={cx('dialogModal', classNameContainer)}
      classNameHeader={classNameHeader}
      classNameBody={classNameBody}
      classNameBackdrop={classNameBackdrop}
      isTvDevice={$_BUILD_RENDERMODE_CSR}
      hideCloseButton={$_BUILD_RENDERMODE_CSR}
      onClose={handleClose}
      size={$_BUILD_RENDERMODE_CSR ? 'medium' : 'small'}
    >
      {description && (
        <p className={styles.dialogModal__description}>{description}</p>
      )}
      <Binder layer={LAYER_DIALOG_MODAL}>
        <ul
          data-testid="dialogModal-list"
          className={styles.dialogModal__options}
        >
          {options.map((option) => {
            const { label } = option;
            const handleClick =
              'handleClick' in option ? option.handleClick : undefined;
            const onClick = 'onClick' in option ? option.onClick : undefined;

            return (
              <li key={`dialogModal__item__${label?.replace(' ', '_')}`}>
                {onClick ? (
                  <Button
                    renderWrapper={({ id, className, children, disabled }) => (
                      <Linker
                        id={id}
                        onClick={() => handleOnClick(onClick)}
                        data={{
                          mainOnClick: onClick,
                          context: getLocationStateContext(
                            onClick?.displayTemplate,
                          ),
                        }}
                        title={label}
                        className={className}
                        disabled={disabled}
                      >
                        {children}
                      </Linker>
                    )}
                    variant={'variant' in option ? option.variant : 'secondary'}
                    width={'width' in option ? option.width : 'full'}
                    font={'font' in option ? option.font : 'hind'}
                  >
                    {label}
                  </Button>
                ) : (
                  <Button
                    onClick={handleClick}
                    width={'width' in option ? option?.width : 'full'}
                    font={'font' in option ? option.font : 'hind'}
                    variant={'variant' in option ? option.variant : 'primary'}
                  >
                    {label}
                  </Button>
                )}
              </li>
            );
          })}
        </ul>
      </Binder>
    </ModalV2>
  );
}

export default DialogModal;
